import * as React from "react"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"

const CoursesDes = props => {
  const { pageContext } = props

  return (
    <>
      <div className="mt-5 px-5 container-custom">
        <div className="comman-para">
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>
            {pageContext.data.attributes.content}
          </ReactMarkdown>
        </div>
      </div>
    </>
  )
}

export default CoursesDes
